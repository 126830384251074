import {AnyAction} from "redux";
import {
    DownloadNotesRequest,
    DownloadScreenerRequest,
    Generic,
    IBillingPatient, ICoCMPatient,
    IPatient, IPatientIndexItem, IPatientListItem,
    IPhysicianNotes,
    IProspect,
    IRefer,
    IReportReference,
    IScreenerQuestionAnswer,
    ISummary,
    ITherapists,
    IcdCodesRequest,
    ScreenedDates, SendGenericPhysicianAppointmentReminderRequest
} from "../../../models";

export const PHYSICIAN_FETCH_PATIENTS="PHYSICIAN_FETCH_PATIENTS"
export const PHYSICIAN_FETCH_PATIENTS_IN_PROGRESS="PHYSICIAN_FETCH_PATIENTS_IN_PROGRESS"
export const PHYSICIAN_FETCH_PATIENTS_SUCCESS="PHYSICIAN_FETCH_PATIENTS_SUCCESS"
export const PHYSICIAN_FETCH_PATIENTS_FAILED="PHYSICIAN_FETCH_PATIENTS_FAILED"

export const PHYSICIAN_FETCH_COCM_PATIENTS="PHYSICIAN_FETCH_COCM_PATIENTS"
export const PHYSICIAN_FETCH_COCM_PATIENTS_IN_PROGRESS="PHYSICIAN_FETCH_COCM_PATIENTS_IN_PROGRESS"
export const PHYSICIAN_FETCH_COCM_PATIENTS_SUCCESS="PHYSICIAN_FETCH_COCM_PATIENTS_SUCCESS"
export const PHYSICIAN_FETCH_COCM_PATIENTS_FAILED="PHYSICIAN_FETCH_COCM_PATIENTS_FAILED"

export const PHYSICIAN_FETCH_PATIENT="PHYSICIAN_FETCH_PATIENT"
export const PHYSICIAN_FETCH_PATIENT_IN_PROGRESS="PHYSICIAN_FETCH_PATIENT_IN_PROGRESS"
export const PHYSICIAN_FETCH_PATIENT_SUCCESS="PHYSICIAN_FETCH_PATIENT_SUCCESS"
export const PHYSICIAN_FETCH_PATIENT_FAILED="PHYSICIAN_FETCH_PATIENT_FAILED"

export const PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY="PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY"
export const PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_IN_PROGRESS="PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_IN_PROGRESS"
export const PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_SUCCESS="PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_SUCCESS"
export const PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_FAILED="PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_FAILED"
export const CLEAR_SCREENED_DATES = "CLEAR_SCREENED_DATES"

export const PHYSICIAN_FETCH_PATIENT_Q_AND_A="PHYSICIAN_FETCH_PATIENT_Q_AND_A"
export const PHYSICIAN_FETCH_PATIENT_Q_AND_A_IN_PROGRESS="PHYSICIAN_FETCH_PATIENT_Q_AND_A_IN_PROGRESS"
export const PHYSICIAN_FETCH_PATIENT_Q_AND_A_SUCCESS="PHYSICIAN_FETCH_PATIENT_Q_AND_A_SUCCESS"
export const PHYSICIAN_FETCH_PATIENT_Q_AND_A_FAILED="PHYSICIAN_FETCH_PATIENT_Q_AND_A_FAILED"

export const PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES =  "PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES"
export const PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES_IN_PROGRESS =  "PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES_IN_PROGRESS"
export const PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES_SUCCESS =  "PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES_SUCCESS"
export const PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES_FAILED =  "PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES_FAILED"
export const PHYSICIAN_REMOVE_PATIENT_RESPONSE_DOWNLOAD_LINK = 'REMOVE_PATIENT_RESPONSE_DOWNLOAD_LINK'

export const PHYSICIAN_FETCH_REFERENCE_NOTES = 'PHYSICIAN_FETCH_REFERENCE_NOTES'
export const PHYSICIAN_FETCH_REFERENCE_NOTES_IN_PROGRESS="PHYSICIAN_FETCH_REFERENCE_NOTES_IN_PROGRESS"
export const PHYSICIAN_FETCH_REFERENCE_NOTES_SUCCESS="PHYSICIAN_FETCH_REFERENCE_NOTES_SUCCESS"
export const PHYSICIAN_FETCH_REFERENCE_NOTES_FAILED="PHYSICIAN_FETCH_REFERENCE_NOTES_FAILED"

export const PHYSICIAN_SAVE_REFER_NOTES =  'PHYSICIAN_SAVE_REFER_NOTES'
export const PHYSICIAN_SAVE_REFER_NOTES_IN_PROGRESS= "PHYSICIAN_SAVE_REFER_NOTES_IN_PROGRESS"
export const PHYSICIAN_SAVE_REFER_NOTES_SUCCESS= "PHYSICIAN_SAVE_REFER_NOTES_SUCCESS"
export const PHYSICIAN_SAVE_REFER_NOTES_FAILED= "PHYSICIAN_SAVE_REFER_NOTES_FAILED"

export const PHYSICIAN_FETCH_REFERRED_PATIENTS="PHYSICIAN_FETCH_REFERRED_PATIENTS"
export const PHYSICIAN_FETCH_REFERRED_PATIENTS_IN_PROGRESS="PHYSICIAN_FETCH_REFERRED_PATIENTS_IN_PROGRESS"
export const PHYSICIAN_FETCH_REFERRED_PATIENTS_SUCCESS="PHYSICIAN_FETCH_REFERRED_PATIENTS_SUCCESS"
export const PHYSICIAN_FETCH_REFERRED_PATIENTS_FAILED="PHYSICIAN_FETCH_REFERRED_PATIENTS_FAILED"

export const PHYSICIAN_FETCH_ALL_THERAPISTS = 'PHYSICIAN_FETCH_ALL_THERAPISTS'
export const PHYSICIAN_FETCH_ALL_THERAPISTS_IN_PROGRESS="PHYSICIAN_FETCH_ALL_THERAPISTS_IN_PROGRESS"
export const PHYSICIAN_FETCH_ALL_THERAPISTS_SUCCESS="PHYSICIAN_FETCH_ALL_THERAPISTS_SUCCESS"
export const PHYSICIAN_FETCH_ALL_THERAPISTS_FAILED="PHYSICIAN_FETCH_ALL_THERAPISTS_FAILED"

export const PHYSICIAN_SAVE_SCHEDULE =  'PHYSICIAN_SAVE_SCHEDULE'
export const PHYSICIAN_SAVE_SCHEDULE_IN_PROGRESS= "PHYSICIAN_SAVE_SCHEDULE_IN_PROGRESS"
export const PHYSICIAN_SAVE_SCHEDULE_SUCCESS= "PHYSICIAN_SAVE_SCHEDULE_SUCCESS"
export const PHYSICIAN_SAVE_SCHEDULE_FAILED= "PHYSICIAN_SAVE_SCHEDULE_FAILED"

export const PHYSICIAN_FETCH_BILLING_PATIENTS="PHYSICIAN_FETCH_BILLING_PATIENTS"
export const PHYSICIAN_FETCH_BILLING_PATIENTS_IN_PROGRESS="PHYSICIAN_FETCH_BILLING_PATIENTS_IN_PROGRESS"
export const PHYSICIAN_FETCH_BILLING_PATIENTS_SUCCESS="PHYSICIAN_FETCH_BILLING_PATIENTS_SUCCESS"
export const PHYSICIAN_FETCH_BILLING_PATIENTS_FAILED="PHYSICIAN_FETCH_BILLING_PATIENTS_FAILED"

export const PHYSICIAN_DOWNLOAD_BILLING_REPORT =  "PHYSICIAN_DOWNLOAD_BILLING_REPORT"
export const PHYSICIAN_DOWNLOAD_BILLING_REPORT_IN_PROGRESS =  "PHYSICIAN_DOWNLOAD_BILLING_REPORT_IN_PROGRESS"
export const PHYSICIAN_DOWNLOAD_BILLING_REPORT_SUCCESS =  "PHYSICIAN_DOWNLOAD_BILLING_REPORT_SUCCESS"
export const PHYSICIAN_DOWNLOAD_BILLING_REPORT_FAILED =  "PHYSICIAN_DOWNLOAD_BILLING_REPORT_FAILED"
export const PHYSICIAN_REMOVE_BILLING_REPORT_DOWNLOAD_LINK = "PHYSICIAN_REMOVE_BILLING_REPORT_DOWNLOAD_LINK"

export const DOWNLOAD_PATIENT_SUMMARY = "DOWNLOAD_PATIENT_SUMMARY"
export const DOWNLOAD_PATIENT_SUMMARY_IN_PROGRESS = "DOWNLOAD_PATIENT_SUMMARY_IN_PROGRESS"
export const DOWNLOAD_PATIENT_SUMMARY_SUCCESS = "DOWNLOAD_PATIENT_SUMMARY_SUCCESS"
export const DOWNLOAD_PATIENT_SUMMARY_FAILED = "DOWNLOAD_PATIENT_SUMMARY_FAILED"
export const REMOVE_PATIENT_SUMMARY_DOWNLOAD_LINK = "REMOVE_PATIENT_SUMMARY_DOWNLOAD_LINK"

export const DOWNLOAD_PATIENT_SESSION_NOTES = "DOWNLOAD_PATIENT_SESSION_NOTES"
export const DOWNLOAD_PATIENT_SESSION_NOTES_IN_PROGRESS = "DOWNLOAD_PATIENT_SESSION_NOTES_IN_PROGRESS"
export const DOWNLOAD_PATIENT_SESSION_NOTES_SUCCESS = "DOWNLOAD_PATIENT_SESSION_NOTES_SUCCESS"
export const DOWNLOAD_PATIENT_SESSION_NOTES_FAILED = "DOWNLOAD_PATIENT_SESSION_NOTES_FAILED"
export const REMOVE_PATIENT_SESSION_NOTES_DOWNLOAD_LINK = "REMOVE_PATIENT_SESSION_NOTES_DOWNLOAD_LINK"

export const PHYSICIAN_FETCH_ICD_CODES = "PHYSICIAN_FETCH_ICD_CODES"
export const PHYSICIAN_FETCH_ICD_CODES_IN_PROGRESS = "PHYSICIAN_FETCH_ICD_CODES_IN_PROGRESS"
export const PHYSICIAN_FETCH_ICD_CODES_SUCCESS = "PHYSICIAN_FETCH_ICD_CODES_SUCCESS"
export const PHYSICIAN_FETCH_ICD_CODES_FAILED = "PHYSICIAN_FETCH_ICD_CODES_FAILED"

export const PHYSICIAN_SAVE_SELECTED_THEARAPIST =  "PHYSICIAN_SAVE_SELECTED_THEARAPIST"

export const PHYSICIAN_CLEAR_REFER_STATUS="PHYSICIAN_CLEAR_REFER_STATUS"

export const SET_SCREENER_SESSION_ID = "SET_SCREENER_SESSION_ID"


export const SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT = "SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT"
export const SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT_SUCCESS = "SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT_SUCCESS"
export const SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT_FAILED = "SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT_FAILED"
export const RESET_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT = "RESET_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT"


export const fetchPatients = (pageNumber: number, recordsPerPage: number, searchText: string, isPotentialReferrals: boolean) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENTS, payload: {pageNumber, recordsPerPage, searchText, isPotentialReferrals}}
}
export const fetchingPatientsFailed = (payload: {error: string}) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENTS_FAILED, payload}
}
export const fetchedPatients = (patients: IPatientListItem[], recordsCount: number, sideBarTab: string) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENTS_SUCCESS, payload: {patients, recordsCount, sideBarTab}}
}

export const fetchCoCMPatients = (pageNumber: number, recordsPerPage: number, searchText: string) : AnyAction => {
    return {type: PHYSICIAN_FETCH_COCM_PATIENTS, payload: {pageNumber, recordsPerPage, searchText}}
}
export const fetchingCoCMPatientsFailed = (payload: {error: string}) : AnyAction => {
    return {type: PHYSICIAN_FETCH_COCM_PATIENTS_FAILED, payload}
}
export const fetchedCoCMPatients = (patients: ICoCMPatient[], recordsCount: number, sideBarTab: string) : AnyAction => {
    return {type: PHYSICIAN_FETCH_COCM_PATIENTS_SUCCESS, payload: {patients, recordsCount, sideBarTab}}
}

export const fetchPatient = (patientId: string) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT, payload: {patientId}}
}
export const fetchPatientInProgress = () : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_IN_PROGRESS}
}
export const fetchingPatientFailed = (payload: {error: string}) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_FAILED, payload}
}
export const fetchedPatient = (patient: IPatient) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_SUCCESS, payload: {patient}}
}

export const fetchPatientWithSummary = (patientId: string, sessionId?: string) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY, payload: {patientId, sessionId}}
}
export const fetchPatientWithSummaryInProgress = () : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_IN_PROGRESS}
}
export const fetchingPatientWithSummaryFailed = (payload: {error: string}) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_FAILED, payload}
}
export const fetchedPatientWithSummary = (patient: IPatient, summary: ISummary, screenedDates: ScreenedDates[]) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_SUCCESS, payload: {patient, summary, screenedDates}}
}
export const clearScreenedDates = () : AnyAction => {
    return {type: CLEAR_SCREENED_DATES}
}

export const fetchPatientQAndA = (patientId: string, screenerId: string) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_Q_AND_A, payload: {patientId, screenerId}}
}
export const fetchPatientQAndAInProgress = () : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_Q_AND_A_IN_PROGRESS}
}
export const fetchingQAndAFailed = (payload: {error: string}) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_Q_AND_A_FAILED, payload}
}
export const fetchedQAndA = (questionAnswers: IScreenerQuestionAnswer[], screenerId: string) : AnyAction => {
    return {type: PHYSICIAN_FETCH_PATIENT_Q_AND_A_SUCCESS, payload: {questionAnswers, screenerId}}
}

export const downloadPatientResponses = (patientId: string, filters?: DownloadScreenerRequest): AnyAction => {
    return { type: PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES, payload: { patientId, filters } }
}
export const downloadingPatientResponsesFailed = (payload: {error: string}) : AnyAction => {
    return {type:  PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES_FAILED, payload}
}
export const downloadedPatientResponses = (link: string, fileName: string) => {
    return {type:  PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES_SUCCESS, payload: {link, fileName}}
}
export const removeDownloadedLink = () : AnyAction => {
    return {type: PHYSICIAN_REMOVE_PATIENT_RESPONSE_DOWNLOAD_LINK}
}

export const saveReferNotesForPatient = (payload: {patientId: string, referringPhysicianId?: string, symptoms?: Generic, problems?: Generic, currentMedications?: Generic, diagnosis?: Generic, comorbidities?: Generic, patientMedicationsHistory?: Generic, psychiatricMedication?: string, physicianNotes?: string, isConsentGiven: string, allergies?: Generic }) => {
    return {type:  PHYSICIAN_SAVE_REFER_NOTES, payload}
}
export const saveReferNotesInProgress = () : AnyAction => {
    return {type:  PHYSICIAN_SAVE_REFER_NOTES_IN_PROGRESS}
}
export const savingReferNotesFailed = (payload: {error: string}) : AnyAction => {
    return {type:  PHYSICIAN_SAVE_REFER_NOTES_FAILED, payload}
}
export const savedReferNotes = (referNotes: IRefer) : AnyAction => {
    return {type:  PHYSICIAN_SAVE_REFER_NOTES_SUCCESS, payload: {referNotes}}
}

export const fetchReferredPatients = (pageNumber: number, recordsPerPage: number, searchText: string, referredPatients?: boolean) : AnyAction => {
    return {type: PHYSICIAN_FETCH_REFERRED_PATIENTS, payload: {pageNumber, recordsPerPage, searchText, referredPatients}}
}
export const fetchingReferredPatientsInProgress = () : AnyAction => {
    return {type: PHYSICIAN_FETCH_REFERRED_PATIENTS_IN_PROGRESS}
}
export const fetchingReferredPatientsFailed = (payload: {error: string}) : AnyAction => {
    return {type: PHYSICIAN_FETCH_REFERRED_PATIENTS_FAILED, payload}
}
export const fetchedReferredPatients = (patients: IPatient[], recordsCount: number, sideBarTab: string) : AnyAction => {
    return {type: PHYSICIAN_FETCH_REFERRED_PATIENTS_SUCCESS, payload: {patients, recordsCount, sideBarTab}}
}

export const fetchReferenceNotes = (patientId: string, slug: string) : AnyAction => {
    return {type: PHYSICIAN_FETCH_REFERENCE_NOTES, payload: {patientId, slug}}
}
export const fetchingReferenceNotesInProgress = () : AnyAction => {
    return {type: PHYSICIAN_FETCH_REFERENCE_NOTES_IN_PROGRESS}
}
export const fetchingReferenceNotesFailed = (payload: {error: string}) : AnyAction => {
    return {type: PHYSICIAN_FETCH_REFERENCE_NOTES_FAILED, payload}
}
export const fetchedReferenceNotes = (physicianReportReference: IReportReference, physicianNotes: IPhysicianNotes | null) : AnyAction => {
    return {type: PHYSICIAN_FETCH_REFERENCE_NOTES_SUCCESS, payload: {physicianReportReference, physicianNotes}}
}

export const fetchAllTherapists = () : AnyAction => {
    return {type: PHYSICIAN_FETCH_ALL_THERAPISTS}
}
export const fetchingAllTherapistsInProgress = () : AnyAction => {
    return {type: PHYSICIAN_FETCH_ALL_THERAPISTS_IN_PROGRESS}
}
export const fetchingAllTherapistsFailed = (payload: {error: string}) : AnyAction => {
    return {type: PHYSICIAN_FETCH_ALL_THERAPISTS_FAILED, payload}
}
export const fetchedAllTherapists = (therapists: ITherapists[]) : AnyAction => {
    return {type: PHYSICIAN_FETCH_ALL_THERAPISTS_SUCCESS, payload: {therapists}}
}

export const saveSchedule = (patientId: string, therapistPracticeId: string) : AnyAction => {
    return {type:  PHYSICIAN_SAVE_SCHEDULE, payload: {patientId, therapistPracticeId}}
}
export const savingScheduleInProgress = () : AnyAction => {
    return {type:  PHYSICIAN_SAVE_SCHEDULE_IN_PROGRESS}
}
export const savingScheduleFailed = (payload: {error: string}) : AnyAction => {
    return {type:  PHYSICIAN_SAVE_SCHEDULE_FAILED, payload}
}
export const savedSchedule = (prospect: IProspect) : AnyAction => {
    return {type:  PHYSICIAN_SAVE_SCHEDULE_SUCCESS, payload: {prospect}}
}

export const fetchBillingPatients = (pageNumber: number, recordsPerPage: number, searchText: string, startDate?: string | null, endDate?: string | null) : AnyAction => {
    return {type: PHYSICIAN_FETCH_BILLING_PATIENTS, payload: {pageNumber, recordsPerPage, searchText, startDate, endDate}}
}
export const fetchingBillingPatientsInProgress = () : AnyAction => {
    return {type: PHYSICIAN_FETCH_BILLING_PATIENTS_IN_PROGRESS}
}
export const fetchingBillingPatientsFailed = (payload: {error: string}) : AnyAction => {
    return {type: PHYSICIAN_FETCH_BILLING_PATIENTS_FAILED, payload}
}
export const fetchedBillingPatients = (billingPatients: IBillingPatient[], recordsCount: number, sideBarTab: string) : AnyAction => {
    return {type: PHYSICIAN_FETCH_BILLING_PATIENTS_SUCCESS, payload: {billingPatients, recordsCount, sideBarTab}}
}

export const downloadBillingReport = (pageNumber: number, recordsPerPage: number, searchText: string, startDate?: string | null, endDate?: string | null): AnyAction => {
    return { type:  PHYSICIAN_DOWNLOAD_BILLING_REPORT, payload: { pageNumber, recordsPerPage, searchText, startDate, endDate } }
}
export const downloadingBillingReportFailed = (payload: { error: string }): AnyAction => {
    return { type:  PHYSICIAN_DOWNLOAD_BILLING_REPORT_FAILED, payload }
}
export const downloadedBillingReport = (link: string, fileName: string) => {
    return { type:  PHYSICIAN_DOWNLOAD_BILLING_REPORT_SUCCESS, payload: { link, fileName } }
}
export const removeBillingReportDownloadedLink = () : AnyAction => {
    return { type: PHYSICIAN_REMOVE_BILLING_REPORT_DOWNLOAD_LINK }
}

export const downloadPatientSummary = (patientId: string): AnyAction => {
    return { type:  DOWNLOAD_PATIENT_SUMMARY, payload: { patientId } }
}
export const downloadingPatientSummaryFailed = (payload: { error: string }): AnyAction => {
    return { type:  DOWNLOAD_PATIENT_SUMMARY_FAILED, payload }
}
export const downloadedPatientSummary = (presignedURL: string, fileName: string) => {
    return { type:  DOWNLOAD_PATIENT_SUMMARY_SUCCESS, payload: { presignedURL, fileName } }
}
export const removePatientSummaryDownloadedLink = () : AnyAction => {
    return { type: REMOVE_PATIENT_SUMMARY_DOWNLOAD_LINK }
}

export const downloadPatientSessionNotes = (request: DownloadNotesRequest): AnyAction => {
    return { type:  DOWNLOAD_PATIENT_SESSION_NOTES, payload: request }
}
export const downloadingPatientSessionNotesFailed = (payload: { error: string }): AnyAction => {
    return { type:  DOWNLOAD_PATIENT_SESSION_NOTES_FAILED, payload }
}
export const downloadedPatientSessionNotes = (presignedURL: string, fileName: string) => {
    return { type:  DOWNLOAD_PATIENT_SESSION_NOTES_SUCCESS, payload: { presignedURL, fileName } }
}
export const removePatientSessionNotesDownloadedLink = () : AnyAction => {
    return { type: REMOVE_PATIENT_SESSION_NOTES_DOWNLOAD_LINK }
}

export const fetchICDCodes = (request: IcdCodesRequest): AnyAction => {
    return {type: PHYSICIAN_FETCH_ICD_CODES, payload: request}
}
export const fetchingICDCodesFailed = (payload: {error: string}): AnyAction => {
    return {type: PHYSICIAN_FETCH_ICD_CODES_FAILED, payload}
}
export const fetchedICDCodes = (icdCodes: string) => {
    return {type: PHYSICIAN_FETCH_ICD_CODES_SUCCESS, payload: {icdCodes}}
}

export const saveSelectedTherapist = (therapistId: string | null) => {
    return {type:  PHYSICIAN_SAVE_SELECTED_THEARAPIST, payload:{therapistId}}
}

export const setScreenerSessionId = (sessionId: string) => {
    return { type: SET_SCREENER_SESSION_ID, payload: { sessionId } }
}

export const clearReferStatus = () => {
    return {type: PHYSICIAN_CLEAR_REFER_STATUS}
}
//
// export const sendScreenerLinkToPatient = (patientId: string, practiceId: string): AnyAction => {
//     return { type: SEND_SCREENER_LINK_TO_PATIENT, payload: { patientId, practiceId } }
// }
//
// export const sendingScreenerLinkToPatientFailed = (payload: { error: string }): AnyAction => {
//     return { type: SEND_SCREENER_LINK_TO_PATIENT_FAILED, payload }
// }
//
// export const sentScreenerLinkToPatient = (): AnyAction => {
//     return { type: SEND_SCREENER_LINK_TO_PATIENT_SUCCESS }
// }
//
// export const resetScreenerLinkToPatient = (): AnyAction => {
//     return { type: RESET_SCREENER_LINK_TO_PATIENT }
// }

export const sendGenericAppointmentReminderToPatient = (request: SendGenericPhysicianAppointmentReminderRequest): AnyAction => {
    return { type: SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT, payload: request }
}

export const sendingGenericAppointmentReminderToPatientFailed = (payload: { error: string }): AnyAction => {
    return { type: SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT_FAILED, payload }
}

export const sentGenericAppointmentReminderToPatient = (): AnyAction => {
    return { type: SEND_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT_SUCCESS }
}

export const resetGenericAppointmentReminderToPatient = (): AnyAction => {
    return { type: RESET_GENERIC_APPOINTMENT_REMINDER_TO_PATIENT }
}
