import React, {PropsWithChildren, useCallback} from "react";
import {useNavigate} from "react-router";
import {SideBarButtonProps, withActiveComponentTreatment, withIconStyling} from "./WithActiveComponentTreatment";

const PhysicianAppointmentsButton: React.FC<PropsWithChildren<SideBarButtonProps>> = ({style, navigateTo, iconRenderer}) => {

    const navigate = useNavigate();

    const navigateToPatients = useCallback(() => {
        navigate(navigateTo);
    }, [navigate, navigateTo]);

    return <div className="flex">
        <button className={style} onClick={navigateToPatients}>
            {iconRenderer("SMS Appointments")}
        </button>
    </div>;
}

export default withActiveComponentTreatment(withIconStyling(PhysicianAppointmentsButton))
