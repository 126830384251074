import { Link, useNavigate, useParams } from "react-router-dom";
import ProspectScreen from "./ProspectScreen"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientWithSummary } from "../../../store/actions/physician/patients.action";
import { fetchSourcePatientDetails } from "../../../store/actions/counsellor/patients.action";
import BackIcon from "../../../assets/images/common/back.svg";
import { StateParams } from "../../../store/reducers";
import _ from "lodash";
import PatientProfile from "../../../components/patients/physician/PatientProfile";

const PatientWithScreeners = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { sourcePatient } = useSelector((state: StateParams) => state.patients);

    useEffect(() => {
        if (params.patientId) {
            dispatch(fetchSourcePatientDetails(params.patientId))
        }
    }, [params.patientId]);

    function renderBackButton() {
        return <div className={"flex flex-row gap-2 items-center"}>
            <Link to={".."} onClick={(e) => {
                e.preventDefault();
                navigate(-1);
            }}>
                <img src={BackIcon} width={24} height={24} alt="Go Back"
                    className={"hover:cursor-pointer"} />
            </Link>
            <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                <span className="text-xl font-semibold text-sjGray"> Patient Profile {'|'} </span>
                <span className="text-xl font-semibold"> {_.capitalize(sourcePatient?.firstName)} {_.capitalize(sourcePatient?.lastName)} </span>
            </div>
        </div>;
    }

    return (
        <div className="w-full p-4 h-full bg-sjGreyishWhite">
            <div className={"py-1 floating-section"}>
                {sourcePatient && renderBackButton()}
            </div>
            <div className="mt-4">
                {sourcePatient && <PatientProfile />}
            </div>
            {sourcePatient && <ProspectScreen />}
        </div>
    )
}

export default PatientWithScreeners