import React, {useMemo} from "react";
import {IScreenerUserForScreeningLink, IUser} from "../../models";
import {MaskPhoneNumber} from "../../components/generic/MaskPhoneNumber";
import {IsActivePill} from "../../components/generic/IsActivePill";
import {UserMasterAction} from "../../components/generic/UserMasterAction";
import {UserRoles} from "../../services/common.service";
import TablePaginationV2 from "../../components/generic/TablePaginationV2";
import {ScreenerUserScreeningSMSAction} from "../../components/generic/ScreenerUserScreeningSMSAction";
import {DateTime} from "luxon";

interface IScreenerUserListComponentProps {
    patients: IScreenerUserForScreeningLink[];
    currentPageHandler: Function;
    searchInProgress: boolean | undefined;
}

const ScreenerUserListComponent = (props: IScreenerUserListComponentProps) => {

    const {patients, searchInProgress} = props;

    const data = patients ? patients.map((patient) => {
        const dob = DateTime.fromISO(patient.dob.toString(), {zone: "utc"}).toFormat('MM/dd/yyyy')
        return {
            id: patient?.id,
            name: `${patient?.firstName} ${patient?.lastName}`,
            dob,
            contactPhoneNumber: patient?.contactPhoneNumber || '-',
            practice: patient?.practice ? patient?.practice?.name : '-',
            action: {
                patientId: patient?.id,
                practiceId: patient?.practice?.id,
                contactPhoneNumber: patient?.contactPhoneNumber
            }
        }
    }) : [];

    const columns = useMemo(
        () => [
            { header: "Name", accessorKey: "name" },
            { header: "DOB", accessorKey: "dob" },
            {
                header: "Phone Number", accessorKey: "contactPhoneNumber", cell: (props: any) =>
                    <MaskPhoneNumber value={props.getValue()} disabled={true} className={'border-0 bg-transparent'}/>
            },
            {header: "Practice", accessorKey: "practice"},
            {
                header: '',
                accessorKey: 'action',
                cell: (props: any) => <ScreenerUserScreeningSMSAction value={props.getValue()}/>
            }
        ],
        []);

    return (
        <div className='mt-4 w-full'>
            <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress}/>
        </div>
    )
}

export default ScreenerUserListComponent;
