import {combineReducers} from "redux";
import LoadingReducer, {LoadingParams} from "./counsellor/loading.reducer";
import PractionerAccountReducer, {PractitionerAccountParams} from "./counsellor/account.reducer";
import SigninReducer, {SigninParams} from "./counsellor/signin.reducer";
import ProspectsReducer, {ProspectsParams} from "./counsellor/prospects.reducer";
import PatientsReducer, {PatientsParams} from "./counsellor/patients.reducer";
import PhysicianPatientsReducer, {PatientsParams as PhysicianPatientsParams} from "./physician/patients.reducer";
import CoordinatorPatientsReducer, {PatientsParams as CoordinatorPatientsParams} from "./care-coordinator/patients.reducer"
import PhysicianAccountReducer, {PhysicianAccountParams as PhysicianAccountParams} from "./physician/account.reducer";
import AppointmentsReducer, {AppointmentsParams} from "./counsellor/appointments.reducer";
import AccessReducer, {AccessParams} from "./counsellor/access.reducer";
import NavigationReducer, {NavigationParams} from "./counsellor/navigation.reducer";
import AccountReducer, {AccountParams} from "./account.reducer";
import CallsReducer, {CallParams} from "./counsellor/calls.reducer";
import PracticeReducer, {PracticeParams} from "./counsellor/practice.reducer";
import AdminOperationsReducer, { AdminOperationsParams } from "./physician/admin-operations.reducer";
import PatientsDashboardReducer, { PatientsDashboardParams } from "./physician/dashboard.reducer";
import NonPhysicianComplianceReducer, {NonPhysicianComplianceParams} from "./counsellor/non-physician-compliance.reducer";
import IntakeReducer, {IntakeParams} from "./counsellor/intake.reducer";
import CareCoordinatorComplianceReducer, { CareCoordinatorComplianceParams } from "./care-coordinator/compliance.reducer";
import CareCoordinatorMasterDataReducer , {CareCoordinatorMasterDataParams} from "./care-coordinator/master-data.reducer";

export interface StateParams {
    loading: LoadingParams,
    navigation: NavigationParams,
    signin: SigninParams,
    access: AccessParams,
    account: AccountParams,
    practitionerAccount: PractitionerAccountParams,
    physicianAccount: PhysicianAccountParams,
    prospects: ProspectsParams
    patients: PatientsParams
    physicianPatients: PhysicianPatientsParams
    appointments: AppointmentsParams,
    calls: CallParams,
    coordinatorPatients: CoordinatorPatientsParams,
    practice: PracticeParams,
    nonPhysicianCompliance: NonPhysicianComplianceParams,
    physicianAdminOperations: AdminOperationsParams
    patientsDashboard: PatientsDashboardParams
    intake: IntakeParams
    careCoordinatorCompliance: CareCoordinatorComplianceParams
    careCoordinatorMasterData: CareCoordinatorMasterDataParams
}

const rootReducer = combineReducers({
    loading: LoadingReducer,
    navigation: NavigationReducer,
    access: AccessReducer,
    practitionerAccount: PractionerAccountReducer,
    account: AccountReducer,
    physicianAccount: PhysicianAccountReducer,
    signin: SigninReducer,
    prospects: ProspectsReducer,
    patients: PatientsReducer,
    physicianPatients: PhysicianPatientsReducer,
    coordinatorPatients: CoordinatorPatientsReducer,
    appointments: AppointmentsReducer,
    calls: CallsReducer,
    practice: PracticeReducer,
    physicianAdminOperations: AdminOperationsReducer,
    patientsDashboard: PatientsDashboardReducer,
    nonPhysicianCompliance: NonPhysicianComplianceReducer,
    intake: IntakeReducer,
    careCoordinatorCompliance: CareCoordinatorComplianceReducer,
    careCoordinatorMasterData: CareCoordinatorMasterDataReducer
})


export default rootReducer;
