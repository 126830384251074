import {AnyAction} from "redux";
import {
    IPracticeMaster,
} from "../../../models";
import {
    FETCH_PRACTICE_MASTER_LIST, FETCH_PRACTICE_MASTER_LIST_FAILED,
    FETCH_PRACTICE_MASTER_LIST_SUCCESS
} from "../../actions/care-coordinator/master-data.action";

export interface CareCoordinatorMasterDataParams {
    practiceMaster: IPracticeMaster[];
    isPracticeMasterSuccess?: boolean;
    isPracticeMasterInProgress?: boolean;
    isPracticeMasterFailed?: boolean;
    practiceMasterError?: string;
}

const initialState: CareCoordinatorMasterDataParams = {
    practiceMaster: [],
    isPracticeMasterSuccess: undefined,
    isPracticeMasterInProgress: undefined,
    isPracticeMasterFailed: undefined,
    practiceMasterError: undefined
}

const CareCoordinatorMasterDataReducer = (state: CareCoordinatorMasterDataParams = initialState, action: AnyAction): CareCoordinatorMasterDataParams => {
    switch (action.type) {
        case FETCH_PRACTICE_MASTER_LIST:
            state = {
                ...state,
                practiceMasterError: undefined,
                isPracticeMasterInProgress: true,
                isPracticeMasterSuccess: false,
                isPracticeMasterFailed: false
            }
            return state;
        case FETCH_PRACTICE_MASTER_LIST_SUCCESS:
            state = {
                ...state,
                practiceMasterError: undefined,
                isPracticeMasterInProgress: false,
                isPracticeMasterSuccess: true,
                isPracticeMasterFailed: false,
                practiceMaster: action?.payload?.practiceMaster || []
            }
            return state;
        case FETCH_PRACTICE_MASTER_LIST_FAILED:
            state = {
                ...state,
                isPracticeMasterInProgress: false,
                isPracticeMasterSuccess: false,
                isPracticeMasterFailed: true,
                practiceMasterError: action?.payload?.error || "Failed to fetch practice master data"
            }
            return state;
        default:
            return state;
    }
}

export default CareCoordinatorMasterDataReducer
