import React, {useCallback, useMemo} from "react";
import {IPhysicianAppointmentListItem} from "../../models";
import TablePaginationV2 from "../../components/generic/TablePaginationV2";
import {DateTime} from "luxon";
import {generateTimestamp} from "../../shared/DateUtils";

interface IPhysicianAppointmentListComponentProps {
    physicianAppointmentList: IPhysicianAppointmentListItem[];
    searchInProgress: boolean | undefined;
}

const PhysicianAppointmentListComponent = (props: IPhysicianAppointmentListComponentProps) => {

    const {physicianAppointmentList, searchInProgress} = props;

    const data = physicianAppointmentList ? physicianAppointmentList?.map((item) => {
        return {
            id: item.id,
            name: `${item?.patient?.firstName} ${item?.patient?.lastName}`,
            dateOfBirth: DateTime.fromISO(item?.patient?.dob, {zone: 'utc'}).toFormat('MM/dd/yyyy'),
            practiceName: item?.practice?.name,
            appointmentTimestamp: generateTimestamp(item?.appointmentDate, item?.appointmentTime),
            appointmentScreeningStatus: item?.appointmentScreeningStatus || 'N/A',
            isSpokenWithPatient: item?.isSpokenWithPatient !== undefined ?  (item?.isSpokenWithPatient ? 'Yes' : 'No') : 'N/A',
            appointmentCallResponse: item?.appointmentCallResponse || 'N/A'
        }
    }) : [];

    const columns = useMemo(
        () => [
            {header: "Name", accessorKey: "name"},
            {header: "Date of Birth", accessorKey: "dateOfBirth"},
            {header: "Practice", accessorKey: "practiceName"},
            {header: "Latest Appointment", accessorKey: "appointmentTimestamp"},
            {header: "Screener Status", accessorKey: "appointmentScreeningStatus"},
            {header: "Called Patient", accessorKey: "isSpokenWithPatient"},
            {header: "Call Response", accessorKey: "appointmentCallResponse"},
        ], []);

    return (
        <div className='mt-4 w-full'>
            <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress}/>
        </div>
    )
}

export default PhysicianAppointmentListComponent;
