import { DateTime } from "luxon";

export const convertISODateToMMDDYYYY = (dateString: string, format: string = 'MM/dd/yyyy', zone = 'utc'): string | null => {
    if (!dateString || !format) return null
    try {
        const parsedDate = DateTime.fromISO(dateString, { zone })
        return parsedDate.toFormat(format)
    } catch(error) {
        console.error('Error parsing date string:', error)
        return null
    }
}

export const formatISODate = (dateString: string, format: string = 'MM/dd/yyyy, hh:mm a'): string | null => {
    if (!dateString || !format) return null
    try {
        const parsedDate = DateTime.fromISO(dateString)
        return parsedDate.toFormat(format)
    } catch(error) {
        console.error('Error parsing date string:', error)
        return null
    }
}

export const getTimeFromMinutes = (minutes: number): string => {
    if (!minutes || minutes === 0) return '00:00';
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${mins < 10 ? '0' : ''}${mins}`;
}

export const getMinutesFromTime = (time: string): number => {
    if (!time || time?.match(/^\d{1,2}:\d{1,2}$/) === null) {
        return 0;
    }
    const [hours, mins] = time?.split(':')?.map(Number);
    return hours * 60 + mins;
}

export const generateTimestamp = (date: string, time: number): string => {
    if (!date || !time) return '';
    return DateTime.fromISO(date, {zone: 'utc'})?.toFormat('MM/dd/yyyy') + ' ' + getTimeFromMinutes(time);
}

