import {AiOutlineClose} from "react-icons/ai";
import React, {useCallback, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import Message from "../../components/generic/Message";
import {IPhysicianAppointmentForm, IPracticeLocation, IProvider} from "../../models";
import {
    addPhysicianAppointment, fetchAllPatientsForScreenerLink, fetchPatientMasterList,
    resetAddPhysicianAppointment
} from "../../store/actions/care-coordinator/patients.action";
import {toast} from "react-toastify";
import Select from "react-select";
import DatePicker from "react-datepicker";
import TimePickerComponent from "../../components/generic/time-picker/TimePickerComponent";
import {getMinutesFromTime} from "../../shared/DateUtils";
import _ from "lodash";

interface IPhysicianAppointmentFormComponentProps {
    onClose: (refreshList: boolean) => void;
}

const PhysicianAppointmentFormComponent = (props: IPhysicianAppointmentFormComponentProps) => {

    const dispatch = useDispatch();
    const {onClose} = props;
    const {
        addPhysicianAppointmentInProgress,
        addedPhysicianAppointment,
        addPhysicianAppointmentSuccess,
        addPhysicianAppointmentError,
        allPatientsForScreenerLinkList,
        allPatientsForScreenerLinkInProgress
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const {practiceMaster} = useSelector((state: StateParams) => state.careCoordinatorMasterData);
    const [locationMaster, setLocationMaster] = useState<IPracticeLocation[]>([]);
    const [providerMaster, setProviderMaster] = useState<IProvider[]>([]);

    const [formError, setFormError] = useState<string>('');

    const {
        register,
        setValue,
        handleSubmit,
        getValues,
        control,
        watch,
        formState: {errors},
    } = useForm<IPhysicianAppointmentForm>({
        defaultValues: {
            practiceId: '',
            locationId: '',
            providerId: '',
            patientId: '',
            appointmentDate: undefined,
            appointmentTime: undefined,
        }
    });

    useEffect(() => {
        if (!addPhysicianAppointmentInProgress && addedPhysicianAppointment && addPhysicianAppointmentSuccess) {
            onClose(true);
            toast("Physician appointment added successfully", {type: "success"});
        }
    }, [addPhysicianAppointmentInProgress, addedPhysicianAppointment, addPhysicianAppointmentSuccess, dispatch, onClose]);

    useEffect(() => {
        if (addPhysicianAppointmentError) {
            setFormError(addPhysicianAppointmentError);
        }
        return () => {
            dispatch(resetAddPhysicianAppointment());
        }
    }, [addPhysicianAppointmentError, dispatch]);

    const onFormChange = useCallback(() => {
        setFormError('');
    }, []);

    const handleDrawerClose = useCallback(() => {
        onClose(false);
        setFormError('');
        dispatch(resetAddPhysicianAppointment());
    }, [onClose, dispatch]);

    const onSubmit = useCallback(handleSubmit((data: IPhysicianAppointmentForm) => {
        console.log("Physician Appointment Form Screen: Save Appointment: ", data);
        const payload = _.cloneDeep(data);
        dispatch(addPhysicianAppointment(payload));
    }), [dispatch, getValues]);

    const handleSearchPatients = useCallback((searchText: string) => {
        if (searchText?.trim()?.length) {
            dispatch(fetchAllPatientsForScreenerLink({
                pageNumber: 1,
                recordsPerPage: 10,
                searchText,
                practiceIds: [watch('practiceId')]
            }));
        }
    }, [dispatch, fetchPatientMasterList, watch]);

    return (
        <div className={"border-r p-5 h-screen"}>
            <div className="mb-5">
                <div className="flex justify-content-between">
                    <div className="text-2xl font-bold text-[#242731] flex-1 mb-4">Add Appointment</div>
                    <div
                        onClick={handleDrawerClose}
                        className="cursor-pointer grow-0"
                    >
                        <AiOutlineClose
                            className="text-gray-300 hover:text-gray-600"
                            style={{width: '25px', height: '25px'}}
                        />
                    </div>
                </div>
                <div
                    className={"text-base text-[#575F6E] font-light"}>Create a new upcoming appointment for the
                    patient’s visit to the practice.
                </div>
            </div>
            <form onSubmit={onSubmit} onChange={onFormChange}>
                <div>
                    <div className={"grid grid-cols-2 gap-10"}>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Practice*</label>
                            <Controller
                                control={control}
                                name='practiceId'
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <Select
                                        placeholder={"Enter or Select Practice"}
                                        className="mt-1 outline-none rounded-md"
                                        options={practiceMaster}
                                        getOptionLabel={option => `${option?.name}`}
                                        getOptionValue={option => option?.id}
                                        value={practiceMaster?.find(x => x?.id === field?.value)}
                                        onChange={x => {
                                            if (!x || !x?.id || x?.id === field?.value) return;
                                            setValue('practiceId', x?.id);
                                            setValue('locationId', '');
                                            setValue('providerId', '');
                                            setValue('patientId', '');
                                            field.onChange(x?.id);
                                            if (x?.locations?.length === 1) {
                                                setValue('locationId', x?.locations[0]?.id);
                                            }
                                            if (x?.providers?.length === 1) {
                                                setValue('providerId', x?.providers[0]?.id);
                                            }
                                            setLocationMaster(x?.locations || []);
                                            setProviderMaster(x?.providers || []);
                                        }}
                                        defaultValue={undefined}
                                        isSearchable={practiceMaster.length > 5}
                                    />
                                )}
                            />
                            {errors?.practiceId?.message &&
                                <Message message={errors?.practiceId?.message} className={'error-msg text-sm'}/>}
                        </div>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Location*</label>
                            <Controller
                                control={control}
                                name='locationId'
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <Select
                                        isDisabled={!locationMaster || locationMaster?.length < 2}
                                        placeholder={"Enter or Select Location"}
                                        className="mt-1 outline-none rounded-md"
                                        options={locationMaster}
                                        getOptionLabel={option => `${option?.name}`}
                                        getOptionValue={option => option?.id}
                                        value={locationMaster?.find(x => x?.id === field?.value) || null}
                                        onChange={x => {
                                            if (!x || !x?.id || x?.id === field?.value) return;
                                            setValue('locationId', x?.id);
                                            field.onChange(x?.id);
                                        }}
                                        isSearchable={locationMaster?.length > 5}
                                    />
                                )}
                            />
                            {errors?.locationId?.message &&
                                <Message message={errors?.locationId?.message} className={'error-msg text-sm'}/>}
                        </div>
                    </div>
                    <div className={"grid grid-cols-2 gap-10"}>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Physician*</label>
                            <Controller
                                control={control}
                                name='providerId'
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <Select
                                        isDisabled={!providerMaster || providerMaster?.length < 2}
                                        placeholder={"Enter or Select Physician"}
                                        className="mt-1 outline-none rounded-md"
                                        options={providerMaster}
                                        getOptionLabel={option => `${option?.firstName} ${option?.lastName}`}
                                        getOptionValue={option => option?.id}
                                        value={providerMaster?.find(x => x?.id === field?.value) || null}
                                        onChange={x => {
                                            if (!x || !x?.id || x.id === field.value) return;
                                            setValue('providerId', x?.id);
                                            field.onChange(x?.id);
                                        }}
                                        isSearchable={providerMaster?.length > 5}
                                    />
                                )}
                            />
                            {errors?.providerId?.message &&
                                <Message message={errors?.providerId.message} className={'error-msg text-sm'}/>}
                        </div>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Patient*</label>
                            <Controller
                                control={control}
                                name='patientId'
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <Select
                                        isDisabled={watch('practiceId').length === 0}
                                        placeholder={"Search for Patient"}
                                        className="mt-1 outline-none rounded-md"
                                        options={allPatientsForScreenerLinkList}
                                        getOptionLabel={option => `${option?.firstName} ${option?.lastName}`}
                                        getOptionValue={option => option?.id}
                                        value={allPatientsForScreenerLinkList?.find(x => x?.id === field?.value) || null}
                                        onInputChange={handleSearchPatients}
                                        onChange={x => {
                                            if (!x || !x?.id || x.id === field.value) return;
                                            setValue('patientId', x?.id);
                                            field.onChange(x?.id);
                                        }}
                                        isSearchable={true}
                                        noOptionsMessage={() => null}
                                    />
                                )}
                            />
                            {errors?.patientId?.message &&
                                <Message message={errors?.patientId?.message} className={'error-msg text-sm'}/>}
                        </div>
                    </div>
                    <div className={"grid grid-cols-2 gap-10"}>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Appointment Date*</label>
                            <Controller
                                {...register("appointmentDate", {required: true})}
                                control={control}
                                name='appointmentDate'
                                render={({field}) => (
                                    <DatePicker
                                        className={`mt-[5px] h-[44px] w-full flex-shrink-0 rounded-md border border-gray-300 placeholder-gray-500 ${
                                            errors?.appointmentDate
                                                ? "text-black border-b-sjLightRed"
                                                : "text-black"
                                        }`}
                                        dateFormat="dd MMM yyyy"
                                        placeholderText="Select Appointment Date"
                                        todayButton="Today"
                                        dropdownMode="select"
                                        isClearable
                                        shouldCloseOnSelect
                                        onChange={(date) => field.onChange(date)}
                                        selected={field?.value}
                                    />
                                )}
                            />
                            {errors.appointmentDate?.message &&
                                <Message message={errors.appointmentDate.message} className={'error-msg text-sm'}/>}
                        </div>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Appointment Time*</label>
                            <Controller
                                {...register("appointmentTime", {required: true})}
                                control={control}
                                name='appointmentTime'
                                render={({field}) => (
                                    <TimePickerComponent
                                        placeholder="Select Appointment Time"
                                        onChange={(time) => {
                                            const timeInMinutes = getMinutesFromTime(time);
                                            setValue('appointmentTime', timeInMinutes);
                                            field.onChange(timeInMinutes);
                                        }}
                                        hasError={!!errors?.appointmentTime}
                                    />
                                )}
                            />
                            {errors?.appointmentTime?.message &&
                                <Message message={errors?.appointmentTime?.message} className={'error-msg text-sm'}/>}
                        </div>
                    </div>
                </div>
                <div className="text-sjDarkRed mt-4 mb-1 pt-2 text-center">{formError}</div>
                <div className={"mt-10"}>
                    <button type={"submit"}
                            disabled={addPhysicianAppointmentInProgress}
                            className={`inline-block text-white rounded shadow py-2 px-5 text-sm ${addPhysicianAppointmentInProgress ? 'bg-sjLightOrange' : 'bg-sjOrange'}`}>
                        {addPhysicianAppointmentInProgress ? "Adding" : "Add Appointment"}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default PhysicianAppointmentFormComponent;
