import {AnyAction} from "redux";
import {
    IPatientMaster,
    IPatientWithSummary, IPhysicianAppointmentListItem,
    IPotentialReferralPatient, IPotentialReferralPatientBasicDetails, IScreenerUserForScreeningLink
} from "../../../models";
import {
    FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS,
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS,
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY_FAILED,
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY,
    FETCH_POTENTIAL_REFERRAL_PATIENTS,
    FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS,
    FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED
} from "../../actions/care-coordinator";
import {
    ADD_PHYSICIAN_APPOINTMENT, ADD_PHYSICIAN_APPOINTMENT_FAILED, ADD_PHYSICIAN_APPOINTMENT_SUCCESS,
    FETCH_ALL_PATIENTS_FOR_SCREENER_LINK,
    FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_FAILED,
    FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_SUCCESS,
    FETCH_PATIENT_BASIC_INFORMATION,
    FETCH_PATIENT_BASIC_INFORMATION_FAILED,
    FETCH_PATIENT_BASIC_INFORMATION_SUCCESS,
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS_BY_SESSION_ID,
    FETCH_PATIENT_MASTER_LIST,
    FETCH_PATIENT_MASTER_LIST_FAILED,
    FETCH_PATIENT_MASTER_LIST_SUCCESS,
    FETCH_PHYSICIAN_APPOINTMENTS,
    FETCH_PHYSICIAN_APPOINTMENTS_FAILED,
    FETCH_PHYSICIAN_APPOINTMENTS_SUCCESS, RESET_ADD_PHYSICIAN_APPOINTMENT,
    RESET_SCREENER_LINK_TO_PATIENT,
    SEND_SCREENER_LINK_TO_PATIENT,
    SEND_SCREENER_LINK_TO_PATIENT_FAILED,
    SEND_SCREENER_LINK_TO_PATIENT_SUCCESS,
    TRANSFER_INTAKE,
    TRANSFER_INTAKE_FAILED,
    TRANSFER_INTAKE_INPROGRESS,
    TRANSFER_INTAKE_SUCCESS,
    UPDATE_PATIENT_BASIC_INFO,
    UPDATE_POTENTIAL_REFERRAL_PATIENT
} from "../../actions/care-coordinator/patients.action";

export interface PatientsParams {
    potentialReferralPatients: IPotentialReferralPatient[]
    potentialReferralPatientCount: number,
    potentialReferralStatusCount: Record<string, number>
    fetchingPatientLatestScreeningSummaryInProgress: boolean,
    errorFetchingPatientLatestScreeningSummary: boolean,
    patientLatestScreeningSummary?: IPatientWithSummary
    patientLatestScreeningSummaryError?: string,
    fetchPotentialReferralPatientsInProgress?: boolean,
    fetchPatientBasicInformationInProgress: boolean,
    errorFetchingPatientBasicInformation: boolean,
    patientBasicInformation?: IPotentialReferralPatientBasicDetails
    patientBasicInformationError?: string
    fetchPatientMasterListInProgress: boolean,
    errorFetchingPatientMasterList: boolean,
    patientMasterList: IPatientMaster[]
    patientMasterCount: number,
    patientMasterListError?: string,
    intakeTransferInProgress?: boolean
    intakeTransferError?: string,
    transferedPatient?: undefined,
    allPatientsForScreenerLinkList: IScreenerUserForScreeningLink[],
    allPatientsForScreenerLinkInProgress: boolean,
    allPatientsForScreenerLinkError?: string,
    patientToSendScreenerLink?: {
        patientId: string,
        practiceId: string
    },
    sendScreenerLinkToPatientInProgress: boolean,
    sendScreenerLinkToPatientError?: string,
    sendScreeningLinkSuccess: boolean,
    physicianAppointmentList: IPhysicianAppointmentListItem[];
    physicianAppointmentListCount: number;
    physicianAppointmentListError?: string;
    physicianAppointmentListInProgress?: boolean;
    addPhysicianAppointmentInProgress?: boolean;
    addPhysicianAppointmentError?: string;
    addPhysicianAppointmentSuccess?: boolean;
    addedPhysicianAppointment?: IPhysicianAppointmentListItem;
}

const initialState: PatientsParams = {
    potentialReferralPatients: [],
    potentialReferralPatientCount: 0,
    potentialReferralStatusCount: {},
    fetchingPatientLatestScreeningSummaryInProgress: false,
    errorFetchingPatientLatestScreeningSummary: false,
    patientLatestScreeningSummary: undefined,
    patientLatestScreeningSummaryError: undefined,
    fetchPatientBasicInformationInProgress: false,
    errorFetchingPatientBasicInformation: false,
    patientBasicInformation: undefined,
    patientBasicInformationError: undefined,
    fetchPatientMasterListInProgress: false,
    errorFetchingPatientMasterList: false,
    patientMasterList: [],
    patientMasterCount: 0,
    patientMasterListError: undefined,
    intakeTransferInProgress: false,
    intakeTransferError: undefined,
    transferedPatient: undefined,
    allPatientsForScreenerLinkList: [],
    allPatientsForScreenerLinkInProgress: false,
    allPatientsForScreenerLinkError: undefined,
    patientToSendScreenerLink: undefined,
    sendScreenerLinkToPatientInProgress: false,
    sendScreenerLinkToPatientError: undefined,
    sendScreeningLinkSuccess: false,
    physicianAppointmentList: [],
    physicianAppointmentListCount: 0,
    physicianAppointmentListError: undefined,
    physicianAppointmentListInProgress: false,
    addPhysicianAppointmentInProgress: false,
    addPhysicianAppointmentError: undefined,
    addPhysicianAppointmentSuccess: false,
    addedPhysicianAppointment: undefined
}

const PatientsReducer = (state: PatientsParams = initialState, action: AnyAction): PatientsParams => {
    switch (action.type) {
        case FETCH_POTENTIAL_REFERRAL_PATIENTS:
            state = {
                ...state,
                potentialReferralPatients: [],
            }
            return state
        case FETCH_POTENTIAL_REFERRAL_PATIENTS_IN_PROGRESS:
            state = {
                ...state,
                fetchPotentialReferralPatientsInProgress: true
            }
            return state
        case FETCH_POTENTIAL_REFERRAL_PATIENTS_FAILED:
            state = {
                ...state,
                fetchPotentialReferralPatientsInProgress: false
            }
            return state
        case FETCH_POTENTIAL_REFERRAL_PATIENTS_SUCCESS:
            state = {
                ...state,
                potentialReferralPatients: action.payload.patients,
                potentialReferralPatientCount: action.payload.count,
                potentialReferralStatusCount: action.payload.statusCount,
                fetchPotentialReferralPatientsInProgress: false
            }
            return state
        case FETCH_PATIENT_LATEST_SCREENING_SUMMARY:
            state = {
                ...state,
                // patientLatestScreeningSummary: undefined,
                patientLatestScreeningSummaryError: undefined,
                fetchingPatientLatestScreeningSummaryInProgress: true,
                errorFetchingPatientLatestScreeningSummary: false
            };
            return state;
        case FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS:
            state = {
                ...state,
                fetchingPatientLatestScreeningSummaryInProgress: false,
                errorFetchingPatientLatestScreeningSummary: false,
                patientLatestScreeningSummary: action.payload.screeningSummary,
                patientLatestScreeningSummaryError: undefined
            };
            return state;
        case FETCH_PATIENT_LATEST_SCREENING_SUMMARY_SUCCESS_BY_SESSION_ID:
            state = {
                ...state,
                fetchingPatientLatestScreeningSummaryInProgress: false,
                errorFetchingPatientLatestScreeningSummary: false,
                patientLatestScreeningSummary: state.patientLatestScreeningSummary ? {
                    ...state.patientLatestScreeningSummary,
                    patient: state.patientLatestScreeningSummary.patient ? {
                        ...state.patientLatestScreeningSummary.patient,
                        screeners: action.payload.screeningSummary.patient.screeners
                    } : action.payload.screeningSummary.patient,
                    summary: action.payload.screeningSummary.summary
                } : action.payload.screeningSummary,
                patientLatestScreeningSummaryError: undefined
            };
            return state;
        case FETCH_PATIENT_LATEST_SCREENING_SUMMARY_FAILED:
            state = {
                ...state,
                fetchingPatientLatestScreeningSummaryInProgress: false,
                errorFetchingPatientLatestScreeningSummary: true,
                patientLatestScreeningSummaryError: action.payload.error
            };
            return state;
        // PATIENT_LATEST_SCREENING_SUMMARY ENDS

        // PATIENT_BASIC_INFORMATION STARTS
        case FETCH_PATIENT_BASIC_INFORMATION:
            state = {
                ...state,
                patientBasicInformation: undefined,
                patientBasicInformationError: undefined,
                fetchPatientBasicInformationInProgress: true,
                errorFetchingPatientBasicInformation: false
            };
            return state;
        case FETCH_PATIENT_BASIC_INFORMATION_SUCCESS:
            state = {
                ...state,
                fetchPatientBasicInformationInProgress: false,
                errorFetchingPatientBasicInformation: false,
                patientBasicInformation: action.payload.patient,
                patientBasicInformationError: undefined
            };
            return state;
        case FETCH_PATIENT_BASIC_INFORMATION_FAILED:
            state = {
                ...state,
                fetchPatientBasicInformationInProgress: false,
                errorFetchingPatientBasicInformation: true,
                patientBasicInformationError: action.payload.error
            };
            return state;
        // PATIENT_BASIC_INFORMATION ENDS


        // PATIENT_MASTER_LIST STARTS
        case FETCH_PATIENT_MASTER_LIST:
            state = {
                ...state,
                patientMasterList: [],
                patientMasterListError: undefined,
                patientMasterCount: 0,
                fetchPatientMasterListInProgress: true,
                errorFetchingPatientMasterList: false
            };
            return state;
        case FETCH_PATIENT_MASTER_LIST_SUCCESS:
            console.log(action);
            state = {
                ...state,
                fetchPatientMasterListInProgress: false,
                errorFetchingPatientMasterList: false,
                patientMasterList: action.payload.patients,
                patientMasterCount: action.payload.count,
                patientMasterListError: undefined
            };
            return state;
        case FETCH_PATIENT_MASTER_LIST_FAILED:
            state = {
                ...state,
                fetchPatientMasterListInProgress: false,
                errorFetchingPatientMasterList: true,
                patientMasterListError: action.payload.error
            };
            return state;
        // PATIENT_MASTER_LIST ENDS

        case UPDATE_POTENTIAL_REFERRAL_PATIENT:
            state = {
                ...state, potentialReferralPatients: state.potentialReferralPatients.map((patient) => {
                    if (patient.id === action.payload.patient.id) {
                        return {...patient, ...action.payload.patient}
                    }
                    return patient
                })
            }
            return state

        case UPDATE_PATIENT_BASIC_INFO:
            state = {
                ...state, fetchPatientBasicInformationInProgress: false,
                patientBasicInformation: state.patientBasicInformation ? {
                    ...state.patientBasicInformation, contactPhoneNumber: action.payload.contactPhoneNumber
                } : undefined
            }
            return state

        case TRANSFER_INTAKE:
            state = {...state, transferedPatient: undefined, intakeTransferError: undefined}
            return state
        case TRANSFER_INTAKE_FAILED:
            state = {...state, intakeTransferInProgress: false, intakeTransferError: action.payload.error}
            return state
        case TRANSFER_INTAKE_INPROGRESS:
            state = {...state, intakeTransferInProgress: true, intakeTransferError: undefined}
            return state
        case TRANSFER_INTAKE_SUCCESS:
            state = {
                ...state,
                transferedPatient: action.payload.noteId,
                intakeTransferInProgress: false,
                intakeTransferError: undefined
            }
            return state
        case FETCH_ALL_PATIENTS_FOR_SCREENER_LINK:
            console.log(action);
            state = {
                ...state,
                allPatientsForScreenerLinkList: [],
                allPatientsForScreenerLinkInProgress: true,
                allPatientsForScreenerLinkError: undefined
            };
            return state;
        case FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_SUCCESS:
            console.log(action.payload);
            state = {
                ...state,
                allPatientsForScreenerLinkList: action.payload.patients,
                allPatientsForScreenerLinkInProgress: false,
                allPatientsForScreenerLinkError: undefined
            };
            return state;
        case FETCH_ALL_PATIENTS_FOR_SCREENER_LINK_FAILED:
            state = {
                ...state,
                allPatientsForScreenerLinkInProgress: false,
                allPatientsForScreenerLinkError: action.payload.error
            };
            return state;
        case SEND_SCREENER_LINK_TO_PATIENT:
            state = {
                ...state,
                patientToSendScreenerLink: action.payload,
                sendScreenerLinkToPatientInProgress: true,
                sendScreeningLinkSuccess: false,
                sendScreenerLinkToPatientError: undefined
            };
            return state;
        case SEND_SCREENER_LINK_TO_PATIENT_FAILED:
            state = {
                ...state,
                sendScreenerLinkToPatientInProgress: false,
                sendScreeningLinkSuccess: false,
                sendScreenerLinkToPatientError: action.payload.error
            };
            return state;
        case SEND_SCREENER_LINK_TO_PATIENT_SUCCESS:
            state = {
                ...state,
                sendScreenerLinkToPatientInProgress: false,
                sendScreeningLinkSuccess: true,
                sendScreenerLinkToPatientError: undefined,
            }
            return state;
        case RESET_SCREENER_LINK_TO_PATIENT:
            state = {
                ...state,
                patientToSendScreenerLink: undefined,
                sendScreenerLinkToPatientInProgress: false,
                sendScreeningLinkSuccess: false,
                sendScreenerLinkToPatientError: undefined
            }
            return state;
        case FETCH_PHYSICIAN_APPOINTMENTS:
            state = {
                ...state,
                physicianAppointmentList: [],
                physicianAppointmentListCount: 0,
                physicianAppointmentListError: undefined,
                physicianAppointmentListInProgress: true
            };
            return state;
        case FETCH_PHYSICIAN_APPOINTMENTS_SUCCESS:
            state = {
                ...state,
                physicianAppointmentList: action?.payload?.data || [],
                physicianAppointmentListCount: action?.payload?.total || 0,
                physicianAppointmentListError: undefined,
                physicianAppointmentListInProgress: false
            };
            return state;
        case FETCH_PHYSICIAN_APPOINTMENTS_FAILED:
            state = {
                ...state,
                physicianAppointmentList: [],
                physicianAppointmentListCount: 0,
                physicianAppointmentListError: action.payload?.error,
                physicianAppointmentListInProgress: false
            };
            return state;
        case ADD_PHYSICIAN_APPOINTMENT:
            state = {
                ...state,
                addPhysicianAppointmentInProgress: true,
                addPhysicianAppointmentError: undefined,
                addPhysicianAppointmentSuccess: false,
                addedPhysicianAppointment: undefined
            };
            return state;
        case ADD_PHYSICIAN_APPOINTMENT_SUCCESS:
            state = {
                ...state,
                addPhysicianAppointmentInProgress: false,
                addPhysicianAppointmentError: undefined,
                addPhysicianAppointmentSuccess: true,
                addedPhysicianAppointment: action?.payload?.data
            };
            return state;
        case ADD_PHYSICIAN_APPOINTMENT_FAILED:
            state = {
                ...state,
                addPhysicianAppointmentInProgress: false,
                addPhysicianAppointmentError: action.payload?.error,
                addPhysicianAppointmentSuccess: false,
                addedPhysicianAppointment: undefined
            };
            return state;
        case RESET_ADD_PHYSICIAN_APPOINTMENT:
            state = {
                ...state,
                addPhysicianAppointmentInProgress: false,
                addPhysicianAppointmentError: undefined,
                addPhysicianAppointmentSuccess: false,
                addedPhysicianAppointment: undefined
            }
            return state;
        default:
            return state;
    }
}

export default PatientsReducer
