import React, {ChangeEvent, useEffect, useState, useCallback} from "react";
import {IPatient, IReportReference} from "../../../../models";
import CreatableSelect from 'react-select/creatable'
import {useDispatch, useSelector} from "react-redux";
import {saveReferNotesForPatient} from "../../../../store/actions/physician/patients.action";
import {useNavigate} from "react-router-dom";
import {StateParams} from "../../../../store/reducers";
import 'react-toastify/dist/ReactToastify.css';
import {RoutePaths} from "../../../../shared/Utils";
import {MixpanelContext} from "../../../../analytics/Mixpanel";
import {REFER_PATIENT_REQUEST, REFER_PATIENT_SUCCESS} from "../../../../analytics/Events";
import ConfirmationModal from "../../../clinical-notes/ConfirmationModal";
import { DateTime } from "luxon";
import { fetchUsers } from "../../../../store/actions/physician/admin-operations.action";
import Select from "react-select";
import { toast } from "react-toastify";

interface PatientProps {
    physicianReportReference: IReportReference
    patient: IPatient
}

interface Checked {
    [key: string]: string
}

const ReferDetails: React.FC<PatientProps> = ({ patient, physicianReportReference }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const patientId = patient.id;
    const analytics = React.useContext(MixpanelContext);
    const { referStatus, saveReferNotesInProgress } = useSelector((state: StateParams) => state.physicianPatients)
    const [sympChecked, setSympChecked] = useState<Checked[]>([]);
    const [sympOther, setSympOther] = useState("")
    const [probChecked, setProbChecked] = useState<Checked[]>([]);
    const [probOther, setProbOther] = useState("")
    const [diagChecked, setDiagChecked] = useState<Checked[]>([]);
    const [diagOther, setDiagOther] = useState("")
    const [comorbChecked, setComorbChecked] = useState<Checked[]>([]);
    const [phyNotes, setPhyNotes] = useState("")
    const [medAllergies, setMedAllergies] = useState("No")
    const [medAllergiesNotes, setMedAllergiesNotes] = useState("")
    const [psychMed, setPsychMed] = useState("No")
    const [consent, setConsent] = useState("Patient would like to continue behavioural health services with another provider")
    const [curMedSelect, setCurMedSelect] = useState<Checked[]>([])
    const [hisMedSelect, setHisMedSelect] = useState<Checked[]>([])
    const {role } = useSelector((state: StateParams) => state.account)
    const [showPhysicianSelectionModal, setShowPhysicianSelectionModal] = useState(false)
    const [physicianId, setPhysicianId]  = useState<string>('');
    const {users,fetchUsersInProgress} = useSelector((state:StateParams) => state.physicianAdminOperations);
    const physicianList = users;



    const handleSympChange = (symptom: Checked) => {
        const isChecked = sympChecked.some((checkedCheckbox: Checked) => checkedCheckbox.name === symptom.name)
        if (isChecked) {
            setSympChecked(
                sympChecked.filter(
                    (checkedCheckbox: Checked) => checkedCheckbox.name !== symptom.name
                )
            );
        } else {
            setSympChecked(sympChecked.concat(symptom));
        }
    };

    const handleProbChange = (problem: Checked) => {
        const isChecked = probChecked.some((checkedCheckbox: Checked) => checkedCheckbox.name === problem.name)
        if (isChecked) {
            setProbChecked(
                probChecked.filter(
                    (checkedCheckbox: Checked) => checkedCheckbox.name !== problem.name
                )
            );
        } else {
            setProbChecked(probChecked.concat(problem));
        }
    };

    const getPhysiciansList = useCallback(() => {
        if (role === 'Medical_Assistant') {
            const activePhysicianListPayload = {
                fetchPhysicians : true,
                status : true
            };
            dispatch(fetchUsers(activePhysicianListPayload))
        }
    }, [role, dispatch]);

    const handleDiagChange = (diagnose: Checked) => {
        const isChecked = diagChecked.some((checkedCheckbox: Checked) => checkedCheckbox.name === diagnose.name)
        if (isChecked) {
            setDiagChecked(
                diagChecked.filter(
                    (checkedCheckbox: Checked) => checkedCheckbox.name !== diagnose.name
                )
            );
        } else {
            setDiagChecked(diagChecked.concat(diagnose));
        }
    };

    const handleComorbChange = (comorbidity: Checked) => {
        const isChecked = comorbChecked.some((checkedCheckbox: Checked) => checkedCheckbox.name === comorbidity.name)
        if (isChecked) {
            setComorbChecked(
                comorbChecked.filter(
                    (checkedCheckbox: Checked) => checkedCheckbox.name !== comorbidity.name
                )
            );
        } else {
            setComorbChecked(comorbChecked.concat(comorbidity));
        }
    };


    const onMedSelection = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'yes') return setPsychMed('Yes')
        else if (e.target.value === 'no') return setPsychMed('No')
    }

    const allergies = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === 'yes'){
                setMedAllergies('Yes')
            }
            else if (e.target.value === 'no'){
                setMedAllergies('No')
            }
        }

    const onConsentSelection = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'yes') return setConsent('Yes')
        else if (e.target.value === 'no') return setConsent('Patient would like to continue behavioural health services with another provider')
    }

    const handleCurMed = (curMedSelect: any) => {
        setCurMedSelect(curMedSelect);
    };

    const handleHisMed = (hisMedSelect: any) => {
        setHisMedSelect(hisMedSelect)
    }

    const Checkbox: React.FC<{ value: string, checked: boolean, onChange: any }> = ({ value, checked, onChange }) => {
        return (
            <label className="text-sm">
                <input type="checkbox" checked={checked} onChange={onChange} className={checked ? 'text-sjOrange' : ''} />
                <span className="ml-1">{value}</span>
            </label>
        );
    };

    const handleSubmit = (e: any) => {
        e.preventDefault()
       if(role === 'Medical_Assistant') {
        setPhysicianId('');
        getPhysiciansList();
        setShowPhysicianSelectionModal(true);
       }else{
        savePatientReferNotes();
       }
    }

    const savePatientReferNotes = () => {
        dispatch(saveReferNotesForPatient({
            patientId: patientId,
            referringPhysicianId: physicianId,
            symptoms: { "selected": sympChecked.map(sym => sym.name), "other": sympOther },
            problems: { "selected": probChecked.map(prob => prob.name), "other": probOther },
            diagnosis: { "selected": diagChecked.map(dia => dia.name), "other": diagOther },
            // comorbidities: { "selected": comorbChecked.map(com => com.name), "other": comorbOther },
            currentMedications: { "selected": curMedSelect.map(curMed => curMed.value) },
            patientMedicationsHistory: { "selected": hisMedSelect.map(hisMed => hisMed.value) },
            psychiatricMedication: psychMed,
            physicianNotes: phyNotes,
            isConsentGiven: consent,
            allergies: {"selected": medAllergies, "other": medAllergiesNotes},
        }))
        analytics?.track(REFER_PATIENT_REQUEST, {patientId, isConsentGiven: consent})
    }

    useEffect(() => {
        if(referStatus === "referred"){
            analytics?.track(REFER_PATIENT_SUCCESS, {patientId})
            if(role === 'Medical_Assistant'){
                navigate(`${RoutePaths.medicalAssistance.prospects}?page=1`, { state: { sidebar: true } })
            }else{
                navigate(`${RoutePaths.physicianCare.prospects}?page=1`, { state: { sidebar: true } })
            }
        }
    }, [referStatus, patientId])

    const handlePatientReferNotes = () => {
        if(physicianId){
            savePatientReferNotes();
        }else{
            toast("Please select physician")
        }
    }

    return (
        <main className="w-full mt-4 p-4 bg-sjWhite border-sjBorderGreen rounded-lg shadow-lg">
            <form onSubmit={handleSubmit}>
                <div>
                    <span>Identified Symptoms/Problems Leading to CoCM Referral</span>
                    <div className="w-full flex mt-2 text-sm">
                        <div className="w-1/2 flex flex-col">
                            <p className="font-bold mb-1">Symptoms</p>
                            {physicianReportReference && physicianReportReference.symptoms.data.map((symptom, index) => {
                                return (
                                    <Checkbox
                                        key={index}
                                        value={symptom.name}
                                        checked={sympChecked.some((checkedCheckbox: any) => checkedCheckbox.name === symptom.name)}
                                        onChange={() => handleSympChange(symptom)}
                                    />
                                )
                            })}
                            <p className="mt-1 ml-4">Other: </p>
                            <input
                                type={"text"}
                                className={"ml-4 w-3/4 h-8 mt-1 text-sm rounded-md focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                                placeholder="Type here"
                                onChange={(e) => setSympOther(e.target.value)}
                            />
                        </div>
                        <div className="w-1/2 flex flex-col">
                            <p className="font-bold mb-1">Problems</p>
                            {physicianReportReference && physicianReportReference.problems.data.map((problem) => {
                                return (
                                    <Checkbox
                                        key={problem.name}
                                        value={problem.name}
                                        checked={probChecked.some((checkedCheckbox: any) => checkedCheckbox.name === problem.name)}
                                        onChange={() => handleProbChange(problem)}
                                    />
                                )
                            })}
                            <p className="mt-1 ml-4">Other: </p>
                            <input
                                type={"text"}
                                className={"ml-4 w-3/4 h-8 mt-1 text-sm rounded-md focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                                placeholder="Type here"
                                onChange={(e) => setProbOther(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <hr className="mt-4 h-[2px] bg-sjGrey" />
                <div className="mt-2">
                    <span>Additional Physician Notes</span>
                    <div className="mt-2">
                        <textarea
                            className="w-full h-28 text-sm rounded-md focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                            placeholder="Type in here"
                            onChange={(e) => setPhyNotes(e.target.value)}
                        />
                    </div>
                </div>
                <hr className="mt-4 h-[2px] bg-sjGrey" />
                <div className="mt-2 ">
                    <span>{`Existing Diagnosis (if known)`}</span>
                    <div className="mt-2 text-sm grid grid-cols-2 ">
                        {physicianReportReference && physicianReportReference.diagnosis.data.map((diagnose, index) => {
                            return (
                                <Checkbox
                                    key={index}
                                    value={diagnose.name}
                                    checked={diagChecked.some((checkedCheckbox: any) => checkedCheckbox.name === diagnose.name)}
                                    onChange={() => handleDiagChange(diagnose)}
                                />
                            )
                        })}
                        <div>
                            <p className="mt-1 ml-4">Other: </p>
                            <input
                                type={"text"}
                                className={"ml-4 w-3/4 h-8 mt-1 text-sm rounded-md focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                                placeholder="Type here"
                                onChange={(e) => setDiagOther(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                {/* <hr className="mt-4 h-[2px] bg-sjGrey" />
                <div className="mt-2 ">
                    <span>{`Comorbidities`}</span>
                    <div className="mt-2 text-sm flex flex-col">
                        {physicianReportReference && physicianReportReference.comorbidities.data.map((comorbidity, index) => {
                            return (
                                <Checkbox
                                    key={index}
                                    value={comorbidity.name}
                                    checked={comorbChecked.some((checkedCheckbox: any) => checkedCheckbox.name === comorbidity.name)}
                                    onChange={() => handleComorbChange(comorbidity)}
                                />
                            )
                        })}
                        <div>
                            <p className="mt-1 ml-4">Other: </p>
                            <input
                                type={"text"}
                                className={"ml-4 w-3/4 h-8 mt-1 text-sm rounded-md focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                                placeholder="Type here"
                                onChange={(e) => setComorbOther(e.target.value)}
                            />
                        </div>
                    </div>
                </div> */}
                <hr className="mt-4 h-[2px] bg-sjGrey" />
                <div className="mt-2">
                    <span>Allergies</span><br></br>
                    <span>(including medication allergies)</span>
                    <div className='flex'>
                        <div className='flex items-center h-12 rounded-md mr-4'>
                            <input id='yes' type="radio" name='allergies' value='yes' onChange={allergies} className='h-3 w-3 mr-2 text-sjOrange focus:ring-0' />
                            <p className='text-sm'>Yes</p>
                        </div>
                        <div className='flex items-center h-12 rounded-md mr-4'>
                            <input id='no' type="radio" name='allergies' defaultChecked value='no' onChange={allergies} className='h-3 w-3 mr-2 text-sjOrange focus:ring-0' />
                            <p className='text-sm'>No</p>
                        </div>
                    </div>
                    <div className="mt-2">
                        <textarea
                            className="w-full h-28 text-sm rounded-md focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                            placeholder="Type in here"
                            disabled={medAllergies === 'No'}
                            value={medAllergiesNotes}
                            onChange={(e) => setMedAllergiesNotes(e.target.value)}
                        />
                    </div>
                </div>
                <hr className="mt-4 h-[2px] bg-sjGrey" />
                <div className="mt-2">
                    <span>Psychiatric Medication </span>
                    <div className='flex'>
                        <div className='flex items-center h-12 rounded-md mr-4'>
                            <input id='yes' type="radio" name='medication' value='yes' onChange={onMedSelection} className='h-3 w-3 mr-2 text-sjOrange focus:ring-0' />
                            <p className='text-sm'>Yes</p>
                        </div>
                        <div className='flex items-center h-12 rounded-md mr-4'>
                            <input id='no' type="radio" name='medication' defaultChecked value='no' onChange={onMedSelection} className='h-3 w-3 mr-2 text-sjOrange focus:ring-0' />
                            <p className='text-sm'>No</p>
                        </div>
                    </div>
                    <div className="flex">
                        <hr className="w-1/2 mt-2.5 h-[2px] ml-2 bg-sjGrey" /><span>Or</span><hr className="w-1/2 mt-2.5 h-[2px] mr-2 bg-sjGrey" />
                    </div>
                    <p className="text-sm">Current psychiatric medications</p>
                    <CreatableSelect
                        isMulti
                        value={curMedSelect}
                        onChange={handleCurMed}
                        isClearable={true}
                        isSearchable={true}
                        isDisabled={psychMed === 'No'}
                        options={physicianReportReference && physicianReportReference.medications.data}
                    />
                    <p className="mt-2 text-sm">Psychiatric medications in patient history</p>
                    <CreatableSelect
                        isMulti
                        value={hisMedSelect}
                        onChange={handleHisMed}
                        isClearable={true}
                        isSearchable={true}
                        isDisabled={psychMed === 'No'}
                        options={physicianReportReference && physicianReportReference.medications.data}
                    />
                    </div>
                <hr className="mt-4 h-[2px] bg-sjGrey" />
                <div className="mt-2">
                    <p>Did the care giver and /or patient provide verbal consent to be referred to CoCM?</p>
                    <div className='flex'>
                        <div className='flex items-center h-12 rounded-md mr-4'>
                            <input id='yes' type="radio" name='consent' required value={'yes'} onChange={onConsentSelection} className='h-3 w-3 mr-2 text-sjOrange focus:ring-0' />
                            <p className='text-sm'>Yes</p>
                        </div>
                        <div className='flex items-center h-12 rounded-md'>
                            <input id='no' type="radio" name='consent' required defaultChecked value={'no'} onChange={onConsentSelection} className='h-3 w-3 mr-2 text-sjOrange focus:ring-0' />
                            <p className='text-sm'>Patient would like to continue behavioural health services with another provider</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <button className='p-2 font-bold rounded-lg  bg-sjOrange text-white' type='submit' value="Submit">
                        {saveReferNotesInProgress ? "Referring..." : "Refer" }
                    </button>
                </div>
            </form>

            <ConfirmationModal  isOpen={showPhysicianSelectionModal}
                                continueButtonName="Refer Patient for CoCM"
                                onClose={() => setShowPhysicianSelectionModal(false)}
                                onConfirm={handlePatientReferNotes}
                                modalClassNames={'!w-1/2'}
                                shouldCloseOnOverlayClick={false}
                                key={'physicianselection'}>

                                                <div className='flex'>
                <p className="text-lg text-left">Referring Patient -</p>
                <p className="text-lg font-semibold text-sjHeader pl-2 text-left">{patient?.firstName + ' ' + patient?.lastName +' ( '+ (patient?.dob ? DateTime.fromISO(patient.dob).toFormat('MM/dd/yyyy') : '') +' )'}</p>
                </div>
                <p className="text-sjGray text-sm text-left pb-2">To proceed with referring the patient, kindly select the referring physician</p>
                <div>
                {!fetchUsersInProgress &&
                <Select
                className="mt-3 outline-none rounded-md"
                options={physicianList}
                isSearchable={true}
                getOptionLabel={option =>`${option.firstName} ${option.lastName} (${option.npi ? option.npi : 'N/A'})`}
                getOptionValue={option => option.id}
                placeholder='Select Physician'
                onChange={(value:any) =>setPhysicianId(value.id)}
                // value={physicianId}
                isMulti={false}
            />
                }
                </div>
            </ConfirmationModal>
        </main>
    )
}

export default ReferDetails
