import React, {FC, ReactNode, useCallback, useState} from 'react';
import Modal from 'react-modal';
import {AiFillCloseCircle} from 'react-icons/ai';
import {Tooltip} from "react-tooltip";

interface ConfirmationModalProps {
    isOpen: boolean
    onClose: () => void
    onConfirm?: () => void
    children?: ReactNode
    actions?: ReactNode
    modalClassNames?: string
    continueButtonName?: string
    continueButtonClassNames?: string
    shouldCloseOnOverlayClick?: boolean
    disableConfirmButton?: boolean
    alignContentInCenter?: boolean
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
                                                           isOpen,
                                                           onClose,
                                                           onConfirm,
                                                           disableConfirmButton,
                                                           children: content,
                                                           actions,
                                                           modalClassNames = '',
                                                           continueButtonName = 'Continue',
                                                           continueButtonClassNames = '',
                                                           shouldCloseOnOverlayClick = true,
                                                           alignContentInCenter = true
                                                       }) => {


    const renderContinue = () => {
        return <button
            id="ok-btn"
            className={`px-4 py-2 text-white text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 ${continueButtonClassNames} ${disableConfirmButton ? "cursor-not-allowed bg-sjLightOrange" : "bg-sjOrange"}`}
            onClick={onConfirm}
            data-testid="confirmationBtn"
            disabled={disableConfirmButton}
        >
            {continueButtonName}
        </button>
    }

    const renderToolTipForCloseConfirmationModal = useCallback(() => {
        return <Tooltip style={{borderRadius: 5, zIndex: 9999}}
                        id="confirmation-modal-close-icon-tooltip">
            <div>
                Close
            </div>
        </Tooltip>
    }, []);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            onRequestClose={onClose}
            key={'confirmAppointmentModal'}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            className={`${modalClassNames} relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white `}
            contentLabel="Confirmation Modal"
            shouldReturnFocusAfterClose={false}
        >
            <div className={`mt-3 ${alignContentInCenter ? 'text-center' : ''}`}>
                {renderToolTipForCloseConfirmationModal()}
                <div
                    onClick={onClose}
                    className="cursor-pointer absolute top-0 right-0 m-1"
                    data-tooltip-id="confirmation-modal-close-icon-tooltip"
                >
                    <AiFillCloseCircle
                        className="text-gray-500 hover:text-gray-700"
                        style={{width: '25px', height: '25px'}}
                    />
                </div>
                <div className="mt-2 px-4 py-3">
                    {content}
                </div>
                <div className="items-center py-3">
                    {onConfirm ? renderContinue() : <>{actions}</>}
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmationModal
