import React, {useCallback, useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {StateParams} from '../../store/reducers'
import TableFilterPaginationV2 from '../../components/generic/TableFilterPaginationV2'
import PaginationV2 from "../../components/generic/PaginationV2";
import screeningSearch from "../../assets/images/common/screeningSearch.svg"
import {
    fetchAllPatientsForScreenerLink,
    fetchedAllPatientsForScreenerLink
} from "../../store/actions/care-coordinator/patients.action";
import ScreenerUserListComponent from "./ScreenerUserListComponent";
import LoadingComponent from "../../components/generic/LoadingComponent";

const SendScreenerLinkScreen = () => {

    const dispatch = useDispatch();
    const {
        allPatientsForScreenerLinkList: patients,
        allPatientsForScreenerLinkInProgress,
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);

    useEffect(() => {
        if (searchParams) {
            if (searchParams.get("page")) {
                setPageNumber(Number(searchParams.get("page")))
            } else {
                setPageNumber(1);
            }
        }
    }, [searchParams])

    useEffect(() => {
        getPatients();
    }, [pageNumber, recordsPerPage, searchText]);

    const getPatients = useCallback(() => {
        if ((pageNumber || recordsPerPage || searchText) && searchText.length > 0) {
            dispatch(fetchAllPatientsForScreenerLink({
                pageNumber,
                recordsPerPage,
                searchText
            }));
        } else {
            dispatch(fetchedAllPatientsForScreenerLink([]));
        }
    }, [pageNumber, recordsPerPage, searchText]);

    const renderFilters = useCallback(() => {
        return <div className='flex flex-row justify-center mt-10 mb-10'>
            <TableFilterPaginationV2
                setTableFilter={setSearchText}
                currentPageHandler={setPageNumber}
                placeholder={"Search by phone number"}
                searchClassNames={"w-96"}
            />
        </div>
    }, []);

    const renderSearchPatientPlaceholderBanner = useCallback(() => {
        return (
            <div className="h-[450px] bg-white flex flex-col items-center justify-center">
                <img src={screeningSearch} width={200} height={200} alt="search" className={"hover:cursor-pointer"} />
                <div className="font-semibold mt-4">Use the search bar to find a patient and send a screening SMS.</div>
            </div>
        )
    }, []);

    const renderPatients = useCallback(() => {
        return <>
            <div>
                <h1 className="text-xl"> Care Link </h1>
                {renderFilters()}
            </div>
            <div className="mt-4 relative">
                {searchText?.length === 0 && renderSearchPatientPlaceholderBanner()}
                <LoadingComponent top={"45%"} left={"45%"} isLoading={allPatientsForScreenerLinkInProgress} />
                {searchText?.length > 0 && <ScreenerUserListComponent
                    searchInProgress={allPatientsForScreenerLinkInProgress}
                    patients={patients}
                    currentPageHandler={setPageNumber}/>}
            </div>
        </>;
    }, [patients, allPatientsForScreenerLinkInProgress, renderFilters]);

    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            {renderPatients()}
        </div>
    )
}

export default SendScreenerLinkScreen;
