import {AnyAction} from "redux";
import {IPracticeMaster} from "../../../models";

export const FETCH_PRACTICE_MASTER_LIST = "FETCH_PRACTICE_MASTER_LIST"
export const FETCH_PRACTICE_MASTER_LIST_SUCCESS = "FETCH_PRACTICE_MASTER_LIST_SUCCESS"
export const FETCH_PRACTICE_MASTER_LIST_FAILED = "FETCH_PRACTICE_MASTER_LIST_FAILED"


export const fetchPracticeMasterList = (): AnyAction => {
    return {type: FETCH_PRACTICE_MASTER_LIST};
}

export const fetchedPracticeMasterList = (practiceMaster: IPracticeMaster[]): AnyAction => {
    return {type: FETCH_PRACTICE_MASTER_LIST_SUCCESS, payload: {practiceMaster}};
}

export const fetchingPracticeMasterFailed = (payload: { error: string }): AnyAction => {
    return {type: FETCH_PRACTICE_MASTER_LIST_FAILED, payload};
}
